import { render, staticRenderFns } from "./CheckInsure.vue?vue&type=template&id=5a84f76d&scoped=true&"
import script from "./CheckInsure.vue?vue&type=script&lang=js&"
export * from "./CheckInsure.vue?vue&type=script&lang=js&"
import style0 from "./CheckInsure.vue?vue&type=style&index=0&id=5a84f76d&lang=less&scoped=true&"
import style1 from "./CheckInsure.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a84f76d",
  null
  
)

export default component.exports