<template>
  <div class="enterMassage">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff">
      <div class="public-title flex flex-pack-justify">
        <strong class="p_r">认证信息</strong>
      </div>

      <div class="qiye" v-show="isEdit">
        <div class="flex item">
          <span class="th">手机号码<font color="red">*</font></span>
          <input type="text" class="flex-1" disabled v-model="enterMessageData.phone" />
          <span class="th">企业基本户开户行<font color="red">*</font><i>{{ enterMessageDataError.accountBank }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.accountBank" @focus="focusDelError('accountBank')" @blur="blurCheck('accountBank')" />
        </div>
        <div class="flex item">
          <span class="th">身份证号码<font color="red">*</font><i>{{ enterMessageDataError.proofNum }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.proofNum" @focus="focusDelError('proofNum')" @blur="blurCheck('proofNum')" />
          <span class="th">企业基本户账号<font color="red">*</font><i>{{ enterMessageDataError.account }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.account" @focus="focusDelError('account')" @blur="blurCheck('account')" />
        </div>
        <div class="flex item">
          <span class="th">邮箱<font color="red">*</font><i>{{ enterMessageDataError.email }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.email" @focus="focusDelError('email')" @blur="blurCheck('email')" />
          <span class="th">法人代表<font color="red">*</font><i>{{ enterMessageDataError.legalRepresence }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.legalRepresence" @focus="focusDelErrorAll" @blur="blurCheck('legalRepresence')" />
        </div>
        <div class="flex item">
          <span class="th">企业名称<font color="red">*</font><i>{{ enterMessageDataError.companyName }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.companyName" @focus="focusDelError('companyName')" @blur="blurCheck('companyName')" />
          <span class="th">法人代表身份证号<font color="red">*</font><i>{{
              enterMessageDataError.legalRepresenceId
            }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.legalRepresenceId" @focus="focusDelErrorAll" @blur="blurCheck('legalRepresenceId')" />
        </div>
        <div class="flex item">
          <span class="th">企业地址<font color="red">*</font><i>{{ enterMessageDataError.companyAddr }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.companyAddr" @focus="focusDelError('companyAddr')" @blur="blurCheck('companyAddr')" />
          <span class="th">法人手机号<font color="red">*</font><i>{{
              enterMessageDataError.legalRepresenceTel
            }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.legalRepresenceTel" @focus="focusDelErrorAll" @blur="blurCheck('legalRepresenceTel')" />
        </div>
        <div class="flex item">
          <span class="th">公司统一信用代码<font color="red">*</font><i>{{ enterMessageDataError.companySocialno }}</i></span>
          <input type="text" class="flex-1" v-model="enterMessageData.companySocialno" @focus="focusDelError('companySocialno')" @blur="blurCheck('companySocialno')" />
        </div>
        <div class="upload flex">
          <div class="txt">
            <em class="di_bl">营业执照(三证合一)<font color="red">*</font></em>
            <p>仅支持PNG、JPG、PDF三文件格式<br />上传文件需小于10M</p>
          </div>
          <div id="drop_area">
            <el-upload class="upload-demo" action="" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :on-change="getFile" :http-request="em" :multiple="false" :file-list="fileList" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PDF">
              <!-- :auto-upload="false" -->
              <!-- :limit="1" -->
              <!-- <font class="el-icon-plus"></font> -->
              <img width="151" height="97" src="../assets/images/uploadBg.png" alt="">
            </el-upload>
            <i class="fileError" v-show="!fileListLength">{{fileError}}</i>
          </div>
        </div>
        <div class="upload flex">
          <div class="txt">
            <em class="di_bl">开户许可证<font color="red">*</font></em>
            <p>仅支持PNG、JPG、PDF三文件格式<br />上传文件需小于10M</p>
          </div>
          <div id="drop_area">
            <el-upload class="upload-demo" action="" list-type="picture-card" :on-preview="handlePictureCardPreview1" :on-remove="handleRemove1" :on-change="getFile1" :http-request="em" :multiple="false" :file-list="fileList1" :before-upload="beforeAvatarUpload" :before-remove="beforeRemove1" accept=".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PDF">
              <!-- :auto-upload="false" -->
              <!-- :limit="1" -->
              <!-- <font class="el-icon-plus"></font> -->
              <img width="151" height="97" src="../assets/images/uploadBg.png" alt="">
            </el-upload>
            <i class="fileError" v-show="!fileListLength1">{{fileError}}</i>
          </div>
        </div>
      </div>
      <table v-show="!isEdit">
        <tr>
        
          <td>企业基本户开户行</td>
          <td>{{ enterMessageData.accountBank }}</td>
          <td>企业基本户账号</td>
          <td>{{ enterMessageData.account }}</td>
        </tr>
        <tr>
          <td>法人代表</td>
          <td>{{ enterMessageData.legalRepresence }}</td>
          <td>企业名称</td>
          <td>{{ enterMessageData.companyName }}</td>
        </tr>
        <tr>
          <td>法人代表身份证号</td>
          <td>{{ enterMessageData.legalRepresenceId }}</td>
          <td>企业地址</td>
          <td>{{ enterMessageData.companyAddr }}</td>
        </tr>
        <tr>
          <td>法人手机号</td>
          <td>{{ enterMessageData.legalRepresenceTel }}</td>
          <td>公司统一信用代码</td>
          <td colspan="3">{{ enterMessageData.companySocialno }}</td>
        </tr>
        <tr>
          <td>营业执照（三证合一）</td>
          <td style="padding-top:10px">
            <el-image style="width: 151px; height: 97px" :src="enterMessageData.pictureUrl" fit="cover" :preview-src-list="srcList">
              <div slot="error" class="image-slot">
                <i class="el-icon-upload"></i> 待上传
              </div>
            </el-image>
          </td>
          <td>开户许可证</td>
          <td style="padding-top:10px">
            <el-image style="width: 151px; height: 97px" :src="enterMessageData.openingAccountUrl" fit="cover" :preview-src-list="srcList1">
              <div slot="error" class="image-slot">
                <i class="el-icon-upload"></i> 待上传
              </div>
            </el-image>
          </td>
        </tr>
      </table>
      <div class="pass" v-if="passStatus"><i class="el-icon-success"></i> 认证通过</div>
      <div class="nopass" v-else><i class="el-icon-warning"></i> 待认证</div>
      <div class="qiye-submit flex flex-align-center flex-pack-center">
        <button class="reset" v-show="isEdit" @click="cancel">
          取消
        </button>
        <button class="edit" v-show="!isEdit" @click="edit">
          修改
        </button>
        <button class="submit" v-show="isEdit" @click="submit">保存</button>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible1">
      <img width="100%" :src="dialogImageUrl1" alt="" />
    </el-dialog>
    <div class="canvasBox" style="display: none">
      <canvas id="the-canvas"></canvas>
    </div>
    <up-phone :visible.sync="upPhoneBoxFlag" :phone="enterMessageData.phone" @UpVisible="UpVisible"></up-phone>
  </div>
</template>

<script>
import formCheck from "../utils/js/formCheck";
import upload from "../utils/js/upload";
import { Message } from "element-ui";
import Menu from "../components/Menu.vue";
import UpPhone from "../components/upPhone.vue";
import toBase64 from "../utils/js/pdfToBase64";
export default {
  name: "enterMessage",
  components: {
    Menu,
    UpPhone,
  },
  data() {
    return {
      enterMessageData: {
        phone: "", //手机号
        proofNum: "", //身份证号
        companyName: "", //企业名称
        companyAddr: "", //企业地址
        companySocialno: "", //统一信用代码
        accountBank: "", //基本开户行
        account: "", //基本账户
        legalRepresence: "", //法定代表人
        legalRepresenceTel: "", //法定代表人电话
        legalRepresenceId: "", //法定代表人身份证
        pictureUrl: "",
        openingAccountUrl: "",
        email: "",
      },
      enterMessageDataError: {
        proofNum: "", //身份证号
        companyName: "", //企业名称
        companyAddr: "", //企业地址
        companySocialno: "", //统一信用代码
        accountBank: "", //基本开户行
        account: "", //基本账户
        legalRepresence: "", //法定代表人
        legalRepresenceTel: "", //法定代表人电话
        legalRepresenceId: "", //法定代表人身份证
        email: "", //邮箱
      },
      dialogVisible: false, //预览弹窗展示
      dialogImageUrl: "", //预览url
      dialogVisible1: false, //预览弹窗展示
      dialogImageUrl1: "", //预览url
      isEdit: false, //是否是编辑状态
      editData: {},
      fileList: [], //上传之后的文件
      fileList1: [], //上传之后的文件
      imgs: "",
      imgs1: "",
      imageUrl: "",
      imageUrl1: "",
      srcList: [],
      srcList1: [],
      fileError: "",
      fileError1: "",
      showUpPhoneBtn: false,
      upPhoneBoxFlag: false,
    };
  },
  computed: {
    fileListLength() {
      return this.fileList.length > 1;
    },
    fileListLength1() {
      return this.fileList1.length > 1;
    },
    passStatus() {
      return (
        this.enterMessageData.companyName &&
        this.enterMessageData.companyAddr &&
        this.enterMessageData.companySocialno &&
        this.enterMessageData.accountBank &&
        this.enterMessageData.account &&
        this.enterMessageData.legalRepresence &&
        this.enterMessageData.legalRepresenceTel &&
        this.enterMessageData.legalRepresenceId &&
        this.enterMessageData.pictureUrl && 
        this.enterMessageData.openingAccountUrl
      );
    },
  },
  mounted() {
    this.getData();
    this.upPhone();
  },
  methods: {
    UpVisible() {
      this.upPhoneBoxFlag = false;
    },
    async upPhone() {
      const res = await this.$getAPI.judgeUser();
      if (res.code !== 1) {
        this.showUpPhoneBtn = true;
      }
    },
    cancel() {
      this.isEdit = false;
      this.getData();
      this.enterMessageDataError = {
        proofNum: "", //身份证号
        companyName: "", //企业名称
        companyAddr: "", //企业地址
        companySocialno: "", //统一信用代码
        accountBank: "", //基本开户行
        account: "", //基本账户
        legalRepresence: "", //法定代表人
        legalRepresenceTel: "", //法定代表人电话
        legalRepresenceId: "", //法定代表人身份证
        email: "", //邮箱
      };
    },
    edit() {
      this.isEdit = true;
    },
    async getData() {
      let res = await this.$getAPI.enterMessageData();
      Object.keys(this.enterMessageData).forEach((v) => {
        this.enterMessageData[v] = res[v];
      });
      if (this.enterMessageData.pictureUrl) {
        this.srcList = [this.enterMessageData.pictureUrl];
        this.fileList = [
          {
            name: this.enterMessageData.pictureUrl,
            url: this.enterMessageData.pictureUrl,
          },
        ];
        document.getElementsByClassName(
          "el-upload--picture-card"
        )[0].style.display = "none";
      }
      if (this.enterMessageData.openingAccountUrl) {
        this.srcList1 = [this.enterMessageData.openingAccountUrl];
        this.fileList1 = [
          {
            name: this.enterMessageData.openingAccountUrl,
            url: this.enterMessageData.openingAccountUrl,
          },
        ];
        document.getElementsByClassName(
          "el-upload--picture-card"
        )[1].style.display = "none";
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    isPDF(data) {
      return data && data.url && data.url.indexOf(".pdf") > -1;
    },
    async submit() {
      if (
        !(
          this.blurCheck("accountBank") &&
          this.blurCheck("proofNum") &&
          this.blurCheck("account") &&
          this.blurCheck("email") &&
          this.blurCheck("companyName") &&
          this.blurCheck("companyAddr") &&
          this.blurCheck("companySocialno") &&
          this.blurCheck("legalRepresence") &&
          this.blurCheck("legalRepresenceId") &&
          this.blurCheck("legalRepresenceTel")
        )
      ) {
        return false;
      }

      if (this.fileList.length < 1) {
        this.fileError = "营业执照不能为空";
        return false;
      }
      if (this.fileList1.length < 1) {
        this.fileError1 = "开户许可证不能为空";
        return false;
      }

      Object.keys(this.enterMessageData).forEach((v) => {
        if (v !== "phone") {
          this.editData[v] = this.enterMessageData[v];
        }
      });
      let putData = this.editData;
      putData["pictureUrl"] = this.fileList[0] ? this.fileList[0].url : "";
      putData["openingAccountUrl"] = this.fileList1[0] ? this.fileList1[0].url : "";
      let res = await this.$getAPI.upEnterMessageData(putData);
      let result = res;
      if (result && result.msg === "统一信用代码有误") {
        this.enterMessageDataError.companySocialno = "社会统一信用代码有误!";
        return;
      }
      if (result && result.msg === "法人信息有误") {
        this.enterMessageDataError.legalRepresence = "法人信息有误";
        this.enterMessageDataError.legalRepresenceTel = "法人信息有误";
        this.enterMessageDataError.legalRepresenceId = "法人信息有误";
        return;
      }
      if (result) {
        this.enterMessageData.pictureUrl = this.fileList[0]
          ? this.fileList[0].url
          : "";
        this.enterMessageData.openingAccountUrl = this.fileList1[0]
          ? this.fileList1[0].url
          : "";
        this.isEdit = false;
        this.$cookies.set(
          "uscn",
          encodeURIComponent(this.editData.companyName),
          null,
          null,
          process.env.VUE_APP_COOKIE_BASE
        );
        window.location.reload();
      }
    },
    // 获取焦点报错重置
    focusDelError(from) {
      this.enterMessageDataError[from] = "";
    },
    focusDelErrorAll() {
      this.enterMessageDataError.legalRepresence = "";
      this.enterMessageDataError.legalRepresenceTel = "";
      this.enterMessageDataError.legalRepresenceId = "";
    },
    // 失去焦点校验
    blurCheck(from) {
      console.log(from)
      let formTextObj = {
        proofNum: formCheck.checkIDCard(this.enterMessageData[from]),
        companyName: formCheck.checkEnterpriseName(this.enterMessageData[from]),
        companyAddr: formCheck.checkEnterpriseAddress(
          this.enterMessageData[from]
        ),
        companySocialno: formCheck.checkSocialCreditCode(
          this.enterMessageData[from]
        ),
        accountBank: formCheck.checkEnterpriseBank(this.enterMessageData[from]),
        account: formCheck.checkSocialCreditAccount(
          this.enterMessageData[from]
        ),
        email: formCheck.checkEmail(this.enterMessageData[from]),
        legalRepresence: formCheck.checkLegalRepresence(this.enterMessageData[from]),
        legalRepresenceId: formCheck.checkLegalRepresenceId(this.enterMessageData[from]),
        legalRepresenceTel: formCheck.checkLegalRepresenceTel(this.enterMessageData[from]),
      };
      if (typeof formTextObj[from] === "string") {
        this.enterMessageDataError[from] = formTextObj[from];
        return false;
      }
      return true;
    },
    showPDF(url) {
      window.open(url);
    },
    upload(file) {
      let _this = this;
      document.getElementsByClassName(
        "el-upload--picture-card"
      )[0].style.display = "none";
      this.getUploadfile(file).then((res) => {
        if (res && res.filePath) {
          _this.fileList = [{ name: res.name, url: res.filePath }];
          _this.fileError = "";
        } else {
          Message({ type: "warning", message: res });
        }
      });
    },
    upload1(file) {
      let _this = this;
      document.getElementsByClassName(
        "el-upload--picture-card"
      )[1].style.display = "none";
      this.getUploadfile(file).then((res) => {
        if (res && res.filePath) {
          _this.fileList1 = [{ name: res.name, url: res.filePath }];
          _this.fileError1 = "";
        } else {
          Message({ type: "warning", message: res });
        }
      });
    },
    handleRemove() {
      setTimeout(function () {
        document.getElementsByClassName(
          "el-upload--picture-card"
        )[0].style.display = "inline-block";
      }, 500);
      this.fileList = [];
    },
    handleRemove1() {
      setTimeout(function () {
        document.getElementsByClassName(
          "el-upload--picture-card"
        )[1].style.display = "inline-block";
      }, 500);
      this.fileList1 = [];
    },
    beforeRemove(file) {
      let flag = true;
      if (file && file.status === "success") {
        flag = this.$confirm(`确认要删除组织机构代码证吗？`).then(() => {
          this.$message.success("已删除");
        });
      }
      return flag;
    },
    beforeRemove1(file) {
      let flag = true;
      if (file && file.status === "success") {
        flag = this.$confirm(`确认要删除开户许可证吗？`).then(() => {
          this.$message.success("已删除");
        });
      }
      return flag;
    },
    async trygetUploadfile() {
      let file = {
        file: this.imgs,
        name:
          String(parseInt(Math.random() * 100)) + String(new Date().getTime()),
      };
      let res = await upload(file, true);
      document.getElementsByClassName(
        "el-upload--picture-card"
      )[0].style.display = "none";
      this.fileList = [{ name: res.filePath, url: res.filePath }];
    },
    async trygetUploadfile1() {
      let file = {
        file: this.imgs1,
        name:
          String(parseInt(Math.random() * 100)) + String(new Date().getTime()),
      };
      let res = await upload(file, true);
      document.getElementsByClassName(
        "el-upload--picture-card"
      )[1].style.display = "none";
      this.fileList1 = [{ name: res.filePath, url: res.filePath }];
    },
    toBlob(dataurl) {
      var arr = dataurl.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    getFile(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (file.raw.type === "application/pdf" && isLt10M) {
        this.usePdfToBase64(file).then((res) => {
          this.imageUrl = res;
          this.imgs = this.toBlob(res);
          this.trygetUploadfile();
          this.fileError = "";
        });
      }
      if (
        (file.raw.type === "image/png" && isLt10M) ||
        (file.raw.type == "image/jpeg" && isLt10M)
      ) {
        this.upload(file);
        this.fileError = "";
      }
    },
    getFile1(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (file.raw.type === "application/pdf" && isLt10M) {
        this.usePdfToBase64(file).then((res) => {
          this.imageUrl1 = res;
          this.imgs1 = this.toBlob(res);
          this.trygetUploadfile1();
          this.fileError1 = "";
        });
      }
      if (
        (file.raw.type === "image/png" && isLt10M) ||
        (file.raw.type == "image/jpeg" && isLt10M)
      ) {
        this.upload1(file);
        this.fileError1 = "";
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async getUploadfile(file) {
      let res = await upload(file);
      res["name"] = res.filePath;
      if (res.filePath.indexOf(".pdf") > -1) {
        this.usePdfToBase64(res.filePath).then((r) => {
          res.filePath = r;
          res.name = String(parseInt(Math.random() * 100));
          return res;
        });
      } else {
        return res;
      }
    },
    async usePdfToBase64(url) {
      return await toBase64(url);
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isJPG) {
        this.$message.error("上传文件只能是 JPG/PNG/PDF格式!");
        return isJPG;
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 10MB!");
        return isLt2M;
      }

      return isJPG && isLt2M;
    },
    em(file) {
      console.log(file);
    },
  },
  beforeDestroy() {
    this.upPhoneBoxFlag = false;
  },
};
</script>

<style lang="less">
.enterMassage {
  margin-left: 185px;
  table {
    width: 97%;
    min-height: 250px;
    margin: 0 auto;
    border: 1px solid #d6d6d6;
    border-collapse: collapse;
    margin-top: 50px;
    td {
      border: #d6d6d6 solid 1px;
      width: 330px;
      &:nth-child(1),
      &:nth-child(3) {
        line-height: 40px;
        background: #f0f0f0;
        text-align: right;
        padding-right: 10px;
        font-weight: bolder;
        width: 160px;
      }
      &:nth-child(2),
      &:nth-child(4) {
        padding-left: 10px;
        padding-right: 20px;
      }
      .upPhoneBtn {
        margin-left: 100px;
        background: #008fff;
        color: #fff;
        border: 0;
        outline: 0;
      }
      .image-slot {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background: #f5f7fa;
        color: #909399;
      }
    }
  }
  .upload-demo {
    width: 151px;
    height: 92px;
    .el-upload--picture-card {
      width: 151px;
      height: 92px;
      border: 0;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 151px;
      height: 92px;
      border: 0;
      img {
        object-fit: cover;
      }
    }
  }
  .fileError {
    color: #f00;
    font-size: 10px;
    font-style: normal;
  }
  .el-icon-close-tip {
    display: none !important;
  }
  .upload-demo .el-upload-list--picture-card .el-upload-list__item.is-ready {
    display: none !important;
  }
  .pass {
    color: #67C23A;
    font-size: 28px;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }
  .nopass {
    color: #E6A23C;
    font-size: 28px;
    font-weight: bold;
    margin-top: 30px;
    text-align: center;
  }
}
</style>