<template>
  <div class="upPassword">
      <Menu></Menu>
      <div class="flex-1 content bor bgfff">
        <div class="public-title flex flex-pack-justify">
            <strong class="p_r">修改密码</strong>
        </div>
        <div class="upPassword-box">
            <div class="upPassword-item">
                <div class="upPassword-label"><span>*</span>旧密码</div>
                <el-input placeholder="请输入旧密码" @focus="checkFormDataError.oldPassword=''" v-model="formData.oldPassword" show-password></el-input>
                <i>{{checkFormDataError.oldPassword}}</i>
            </div>
            <div class="upPassword-item">
                <div class="upPassword-label"><span>*</span>新密码</div>
                <el-input @blur="blurCheck('newPassword')" @focus="checkFormDataError.newPassword=''" placeholder="请输入新密码，密码长度8-20位，需包含大小写字母和数字" maxlength="20" v-model="formData.newPassword" show-password></el-input>
                <i>{{checkFormDataError.newPassword}}</i>
            </div>
            <div class="upPassword-do">
                <el-link type="primary" @click="forgetPassword">忘记密码？去找回</el-link>
            </div>
            <div class="upPassword-btn">
                <el-button type="primary" @click="submit">确认修改</el-button>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import Menu from '../components/Menu.vue'
import formCheck from '../utils/js/formCheck'
export default {
    components: { Menu },
    name: 'UpPassword',
    data() {
        return {
            formData: {
                oldPassword: '',
                newPassword: '',
            },
            checkFormDataError: {
                oldPassword: '',
                newPassword: '',
            }
        }
    },
    methods: {
        blurCheck(from) {
            let formTextObj = {
                newPassword: formCheck.checkPassword(this.formData[from]),
            };
            console.log(formTextObj)
            if (typeof formTextObj[from] === "string") {
                this.checkFormDataError[from] = formTextObj[from];
                return false;
            }
            return true;
        },
        forgetPassword () {
            location.href = `${process.env.VUE_APP_BASE_URL}/forgetPwd/init.do`
        },
        async submit() {
            if (!this.blurCheck('newPassword')) {
                return;
            }
            let res = await this.$getAPI.changePwd({
                oldPwd: this.$md5(this.formData.oldPassword),
                newPwd: this.$md5(this.formData.newPassword)
            })
            if (res.code === 4) {
                this.checkFormDataError.oldPassword = '您的旧密码错误，请重试'
            }
            if (res.code === 1) {
                this.$alert('成功修改密码，请重新登录', '提示',{
                    confirmButtonText: '确定',
                    callback: () => {
                        this.$cookies.remove('TSESSIONID','/', process.env.VUE_APP_COOKIE_BASE);
                        this.$cookies.remove('uscn','/', process.env.VUE_APP_COOKIE_BASE);
                        window.location.replace(`${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}`)
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.upPassword {
    margin-left: 185px;
    &-box {
        padding: 30px 24px;
        width: 46%;
        margin-left: 80px;
        margin-top: 50px;
    }
    &-item {
        margin-bottom: 20px;
        position: relative;
        span {
            color: #f00;
        }
        i {
            position: absolute;
            left: 0;
            bottom: -20px;
            font-size: 10px;
            color: #f00;
            font-weight: bold;
            white-space: nowrap;
            font-style: normal;
            line-height: 12px;
        }
    }
    &-do {
       text-align: right;
    }
    &-btn {
        text-align: center;
        margin-top: 40px;
    }
}
</style>