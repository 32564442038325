<template>
  <div class="invoice">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff">
      <div class="public-title flex flex-pack-justify">
        <strong class="p_r">我的发票</strong>
      </div>
      <div class="myOrder-search">
        <div class="myOrder-search-item">
          <label>招标项目编号</label>
          <el-input
            style="width: 130px"
            v-model="idValue"
            placeholder=""
            size="medium"
          ></el-input>
        </div>
        <div class="myOrder-search-item">
          <label>申请发票时间</label>
          <!-- :clearable="false" -->
          <el-date-picker
            v-model="dateValue"
            type="date"
            size="small"
            placeholder="选择日期"
            :clearable="false"
            @change="upDateValue"
          >
            <!-- clear-icon="timeClear" -->
          </el-date-picker>
        </div>
        <div class="myOrder-search-btn">
          <el-button type="primary" id="search" @click="search">查询</el-button>
          <el-button id="reset" @click="clear">重置</el-button>
        </div>
      </div>

      <div class="fapiao">
        <el-table
          empty-text="无内容"
          height="400"
          :data="invoiceList"
          style="width: 100%"
        >
          <el-table-column
            fixed
            width="55"
            type="index"
            :index="indexMethod"
            label="序号"
          >
          </el-table-column>
          <el-table-column label="项目信息" width="330">
            <template slot-scope="scope">
              <div class="row">
                <span>招标项目编号：</span>
                <p>{{ scope.row.projectId }}</p>
              </div>
              <div class="row">
                <span>项目名称：</span>
                <p>{{ scope.row.projectName2 }}</p>
              </div>
              <div class="row">
                <span>项目区域：</span>
                <p>{{ scope.row.mergername }}</p>
              </div>
              <div class="row">
                <span>开标日期：</span>
                <p>{{ scope.row.tenderTime }}</p>
              </div>
              <div class="row">
                <span>保证金金额：</span>
                <p>{{ scope.row.bailAmt }}</p>
              </div>
              <div class="row">
                <span>保函开具对象：</span>
                <p>{{ scope.row.tendereeName }}</p>
              </div>
              <p></p>
            </template>
          </el-table-column>
          <el-table-column label="业务信息" width="230">
            <template slot-scope="scope">
              <div class="row">
                <span>业务类型：</span>
                <p>{{ getBusinessType(scope.row.bizId) }}</p>
              </div>
              <div class="row">
                <span>申请银行/保险公司：</span>
                <p>{{ scope.row.dictName }}</p>
              </div>
              <div class="row" v-if="scope.row.premium">
                <span>保费：</span>
                <p>{{ scope.row.premium }}</p>
              </div>
              <div class="row" v-if="scope.row.loanAmt">
                <span>申请保额：</span>
                <p>{{ scope.row.loanAmt }}</p>
              </div>
              <div class="row" v-if="scope.row.auditEndtime">
                <span>审批时间：</span>
                <p>{{ scope.row.auditEndtime }}</p>
              </div>
              <div class="row" v-if="scope.row.reason">
                <span>未通过原因：</span>
                <p>{{ scope.row.reason }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column width="77" label="状态" align="center">
            <template slot-scope="scope">
              <p :class="{ blue: scope.row.fpxzUrl }">
                {{ scope.row.fpxzUrl ? "开具成功" : "待开具" }}
              </p>
            </template>
          </el-table-column>
          <el-table-column width="70" prop="userName" label="申请人">
          </el-table-column>
          <el-table-column label="申请发票时间" width="105" align="center">
            <template slot-scope="scope">
              <p>{{ ymdsmmToYMD(scope.row.updateStatusTime) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="btns">
                <a href="javascript:;" class="di_in" v-if="scope.row.fpxzUrl" @click="showInvoice(scope.row.processId)"
                  >下载发票</a
                >
                <a
                  href="javascript:void(0);"
                  class="di_in fapiao-add"
                  @click="showInvoice(scope.row.processId)"
                  v-else
                  >开具发票</a
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 分页开始 -->
      <div class="paging">
        <el-pagination
          background
          layout="prev, pager, next, sizes,jumper,slot"
          :page-sizes="[10, 20, 30, 50, 100]"
          :page-size="count"
          :total="total"
          prev-text="上一页"
          next-text="下一页"
          @size-change="upCountPage"
          @current-change="upPage"
        >
          <el-button
            size="small"
            class="pagebtn"
            style="
              border: 1px solid #dcdcdc;
              box-shadow: 0px 2px 4px 0px rgb(0 117 230 / 77%);
              border-radius: 4px;
              background: #fff;
            "
            >确定</el-button
          >
        </el-pagination>
      </div>

      <!-- 分页结束 -->
    </div>
    <!-- 开具发票 -->
    <div class="theme-fapiao" v-show="addInvoiceFlag">
      <div class="title p_r">
        <a
          href="javascript:;"
          title="关闭"
          class="close"
          @click="addInvoiceFlag = false"
        ></a>
        <span class="di_bl flex flex-align-center flex-pack-center"
          >开具发票</span
        >
      </div>
      <div class="box">
        <div class="item flex flex-align-center" v-show="false">
          <span>发票类型</span
          ><label
            ><input
              type="radio"
              name="RadioGroup1"
              value="单选"
              id="RadioGroup1_0"
              checked="checked"
            />普通发票</label
          >
        </div>
        <div class="item flex p_r">
          <span>发票抬头</span>
          <input type="text" id="" v-model="invoiceObj.head" maxlength="150" class="flex-1 bor" value="" @blur="checkBlur('head')" @focus="focusDelError('head')"/>
          <i>{{invoiceObjError.head}}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人编号</span>
          <input type="text" id="" v-model="invoiceObj.code" maxlength="150" class="flex-1 bor" value="" @blur="checkBlur('code')" @focus="focusDelError('code')"/>
          <i>{{invoiceObjError.code}}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人地址</span>
          <input type="text" id="" v-model="invoiceObj.address" maxlength="150" class="flex-1 bor" value="" @blur="checkBlur('address')" @focus="focusDelError('address')"/>
          <i>{{invoiceObjError.address}}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人银行</span>
          <input type="text" id="" v-model="invoiceObj.bank" maxlength="150" class="flex-1 bor" value="" @blur="checkBlur('bank')" @focus="focusDelError('bank')"/>
          <i>{{invoiceObjError.bank}}</i>
        </div>
        <div class="item flex">
          <span>发票纳税人账户</span>
          <input type="text" id="" v-model="invoiceObj.bankCode" maxlength="150" class="flex-1 bor" value=""  @blur="checkBlur('bankCode')" @focus="focusDelError('bankCode')"/>
          <i>{{invoiceObjError.bankCode}}</i>
        </div>
        <div class="item flex">
          <span>电子发票接收邮箱</span>
          <input type="text" id="" v-model="invoiceObj.email"  maxlength="150" class="flex-1 bor" value=""  @blur="checkBlur('email')" @focus="focusDelError('email')"/>
          <i>{{invoiceObjError.email}}</i>
        </div>
        <div class="item flex">
          <span>电子发票接收联系电话</span>
          <input type="text" id="" v-model="invoiceObj.phone"  maxlength="150" class="flex-1 bor" value=""  @blur="checkBlur('phone')" @focus="focusDelError('phone')"/>
          <i>{{invoiceObjError.phone}}</i>
        </div>
        <div class="item flex">
          <span>备注</span>
          <input type="text" id="" v-model="invoiceObj.remark" class="flex-1 bor" value=""/>
        </div>

        <div class="submit flex flex-align-center flex-pack-center">
          <input type="reset" value="取消" @click="addInvoiceFlag=false"/>
          <input type="submit" id="" name="" value="开具发票" @click="putInvoice"/>
        </div>
      </div>
    </div>
    <div class="theme-fapiao"  v-show="waitFlag">
      <div class="box">
        <div class="txt te_c">发票加速开具中，请耐心等待~</div>
        <div class="submit flex flex-align-center flex-pack-center">
          <input type="submit" id="" name="" value="确定" @click="waitFlag=false"/>
        </div>
      </div>
    </div>

    <div class="theme-popover-mask" v-show="addInvoiceFlag || waitFlag"></div>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import formCheck from "../utils/js/formCheck";
export default {
  name: "invoice",
  components: {
    Menu,
  },
  data() {
    return {
      idValue: "",
      dateValue: "",
      dateValueApi: "",
      addInvoiceFlag: false,
      waitFlag: false,
      invoiceList: [],
      count: 10,
      total: 0,
      pageNum: 1,
      invoiceObj: {
        head: '', 
        code: '',
        address: '',
        bank: '',
        bankCode: '',
        email: '',
        phone: '',
        remark: ''
      },
      invoiceObjError: {
        head: '', 
        code: '',
        address: '',
        bank: '',
        bankCode: '',
        email: '',
        phone: '',
        remark: ''
      },
      invoiceId: ''
    };
  },
  mounted() {
    this.getInvoiceList();
  },
  methods: {
    add0(num) {
      return num >= 10 ? num : `0${num}`;
    },
    getBusinessType(code) {
      return code === "01" ? "投标贷" : code === "02" ? "中标贷" : "投标保函";
    },
    ymdsmmToYMD(str) {
      return str.substr(0, 10);
    },
    indexMethod(index) {
      return this.add0(String(index + 1 + (this.pageNum - 1) *this.count));
    },
    async getInvoiceList() {
      var res = await this.$getAPI.invoiceData({
        pageNum: this.pageNum,
        pageSize: this.count,
        projectId: this.idValue,
        billTime: this.dateValueApi,
      });
      this.invoiceList = res.data;
      this.total = res.total;
    },
    upDateValue(date) {
      console.log(date);
      this.dateValueApi = !date
        ? ""
        : `${new Date(date).getFullYear()}-${this.add0(
            new Date(date).getMonth() + 1
          )}-${this.add0(new Date(date).getDate())}`;
    },
    upCountPage(count) {
      this.count = count;
      this.getInvoiceList();
    },
    upPage(pageNum) {
      this.pageNum = pageNum;
      this.getInvoiceList();
    },
    search() {
      this.pageNum = 1;
      this.getInvoiceList();
    },
    clear() {
      this.dateValue = "";
      this.idValue = "";
      this.dateValueApi = "";
      // this.getInvoiceList();
    },
    async showInvoice(processId) {
      let res = await this.$getAPI.addInvoice({ processId });
      let data = res.data;
      if (data.success == "1") {
        this.invoiceId = processId;
        this.addInvoiceFlag = true
      } else if (data.success == "2") {
        this.$alert('发票尚未生成，银行正在处理中，请稍后再试', '警告', {showConfirmButton: false, callback(){}});
      } else if (data.success == "3") {
        window.location.href = data.url;
      } else if (data.success == "4") {
        this.$alert(`请联系客服`, '警告', {showConfirmButton: false, callback(){}});
      } else if (data.success == "5") {
        this.$alert('发票申请已发送，请两小时后到个人中心下载发票！', '警告', {showConfirmButton: false, callback(){}});
      } else if (data.success == "6") {
        this.$alert('暂不支持下载电子发票，请联系客服邮寄纸质发票！', '警告', {showConfirmButton: false, callback(){}});
      } else if (data.success == "7") {
        window.open(data.url);
      }
      // else if (data.success == '11') {
      //   // location.href = `http://118.31.16.107:6092/pingan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //   window.open(`http://118.31.16.107:6092/pingan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      // } else if (data.success == '12') {
      //   window.open(`http://118.31.16.107:6098/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      //
      // } else if (data.success == '13') {
      //   window.open(`http://118.31.16.107:6092/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      //
      // } else if (data.success == '14') {
      //   window.open(`http://118.31.16.107:6092/yongan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      //
      // } else if (data.success == '15') {
      //   // location.href = `http://http://118.31.16.107:6092/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`
      //   window.open(`https://api.zhonghuijingji.com/ecooperation/tianan/Route/Ecooperation?toubaono=${data.pab.toubaono}&platformcode=${data.pab.platformcode}&timestamp=${data.pab.timestamp}&sign=${data.pab.sign}`,'_blank');
      //
      // }
    },
    // 开发票
    async putInvoice () {
      if (!(
        this.checkBlur('head') &&
        this.checkBlur('code') &&
        this.checkBlur('address') &&
        this.checkBlur('bank') &&
        this.checkBlur('bankCode') &&
        this.checkBlur('email') &&
        this.checkBlur('phone') 
      )) {
        return false;
      }
      let obj = {
        invoiceOwner: this.invoiceObj.head,
        taxPayerNO: this.invoiceObj.code,
        taxPayerAddress: this.invoiceObj.address,
        taxPayerBankName: this.invoiceObj.bank,
        taxPayerBankAccount: this.invoiceObj.bankCode,
        invoiceEmail: this.invoiceObj.email,
        invoicePhone: this.invoiceObj.phone,
        remark: this.invoiceObj.remark,
        processId: this.invoiceId
      }    
      let res = await this.$getAPI.updateInvoice(obj)
      let data = res.data
      if (data.success == "1") {
        this.addInvoiceFlag = false;
          window.location.href = data.url;
      } else if (data.success == "2") {
          this.addInvoiceFlag = false;
          this.waitFlag = true;
      }
    },
    checkBlur(from) {
      let formTextObj = {
        head: formCheck.checkInvoiceHead(this.invoiceObj[from]), 
        code: formCheck.checkInvoiceCode(this.invoiceObj[from]),
        address: formCheck.checkInvoiceAddress(this.invoiceObj[from]),
        bank: formCheck.checkInvoiceBank(this.invoiceObj[from]),
        bankCode: formCheck.checkInvoiceBankCode(this.invoiceObj[from]),
        email: formCheck.checkInvoiceEmail(this.invoiceObj[from]),
        phone: formCheck.checkInvoicePhone(this.invoiceObj[from]),
      };
      if (typeof formTextObj[from] === "string") {
        this.invoiceObjError[from] = formTextObj[from];
        return false;
      }
      console.log(from);
      return true;
    },
    // 获取焦点报错重置
    focusDelError(from) {
      this.invoiceObjError[from] = "";
    },
  },
};
</script>

<style lang="less" scoped>
.invoice {
  margin-left: 185px;
  .paging {
    margin: 40px 20px;
  }
  .fapiao {
    td:nth-child(2),
    td:nth-child(3) {
      text-align: left;
      padding-left: 2em;
      line-height: 28px;
    }
  }
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      white-space: nowrap;
    }
    p {
      margin: 0;
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .el-table__header {
    border-top: 2px#E60012 solid;
  }
}
.myOrder {
  &-search {
    // margin-top: 20px;
    display: flex;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      border-radius: 8px;
      height: 32px;
      label {
        background-color: #ba1216;
        color: #fff;
        line-height: 32px;
        padding: 0 10px;
        border-radius: 8px 0 0 8px;
      }
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #959595;
        border-left: 0;
        overflow: hidden;
        border-radius: 0 8px 8px 0;
      }
      /deep/ .searchId {
        width: 130px;
      }
    }
    &-btn {
      display: inline-block;
      margin-left: 20px;
      #search {
        background-image: url(../assets/images/search.png);
        background-repeat: no-repeat;
        background-position: 7px 7px;
        padding-left: 36px;
        height: 40px;
        margin: 20px 0;
      }
      #reset {
        width: 80px;
        border: 1px#BA1216 solid;
        color: #ba1216;
        background-color: #fff;
        border-radius: 4px;
        margin-left: 20px;
      }
    }
  }
}
</style>
<style lang="less">
.myOrder-search {
  .el-select .el-input.is-focus .el-input__inner {
    border: 0;
  }
  .el-input__inner {
    border: 0;
  }
  .el-input--small .el-input__inner {
    height: 32px;
    padding-right: 0;
  }
  .el-input__suffix-inner {
    position: relative;
    right: 20px;
  }
}
.pagebtn {
  border: 1px solid #dcdcdc;
  box-shadow: 0px 2px 4px 0px rgb(0 117 230 0.77);
  border-radius: 4px;
  background: #fff;
  margin-left: 10px;
}
// .el-table--scrollable-y .el-table__body-wrapper {
//   &::-webkit-scrollbar {
//     width:1px;
//     height:16px;
//     background-color:#d6d6d6;
//   }

//   &::-webkit-scrollbar-thumb
//   {
//     border-radius:10px;
//     background-color:#333;
//   }
// }
</style>