<template>
  <div v-if="visible" class="upPhone flex-1 bor bgfff">
    <div class="public-title flex flex-pack-justify">
      <strong class="p_r">更换绑定手机号</strong>
    </div>
    <h3 style="color:rgba(0,0,0,0.6)"><i class="el-icon-info" style="color:#E6A23C"></i> 换绑手机号之后，可以用新的手机号及当前密码登陆</h3>
    <ul>
      <li>
        <div class="upPhone-label">手机号：</div>
        <div class="upPhone-item">{{phone}}
          <!-- <el-input
            v-model="phone"
            size="mini"
            maxlength="11"
            disabled
          ></el-input> -->
        </div>
      </li>
      <li>
        <div class="upPhone-label">当前密码：</div>
        <div class="upPhone-item">
          <el-input
            placeholder="请输入当前密码"
            v-model="formData.password"
            size="mini"
            show-password
            @blur="blurCheck('password')"
            @focus="formDataError.password = ''"
          ></el-input>
          <i>{{ formDataError.password }}</i>
        </div>
      </li>
      <li>
        <div class="upPhone-label">新手机号：</div>
        <div class="upPhone-item">
          <el-input
            placeholder="请输入新手机号"
            v-model="formData.newPhone"
            size="mini"
            maxlength="11"
            @blur="blurCheck('newPhone')"
            @focus="formDataError.newPhone = ''"
          ></el-input>
          <i>{{ formDataError.newPhone }}</i>
        </div>
      </li>
      <li>
        <div class="upPhone-label">验证码：</div>
        <div class="upPhone-item">
          <el-input
            placeholder="请输入验证码"
            v-model="formData.code"
            size="mini"
            maxlength="11"
            @blur="blurCheck('code')"
            @focus="formDataError.code = ''"
          ></el-input>
          <el-button type="primary" size="mini" class="getcode" :disabled="codeDisStatus" @click="getcode"
            >{{codeText}}</el-button
          >
          <i>{{ formDataError.code }}</i>
        </div>
      </li>
    </ul>
    <div class="upPhone-do">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确认修改</el-button>
    </div>
  </div>
</template>

<script>
import formCheck from '../utils/js/formCheck'
export default {
  name: "UpPhone",
  props: ["visible", "phone"],
  data() {
    return {
      formData: {
        password: "",
        newPhone: "",
        code: "",
      },
      formDataError: {
        password: "",
        newPhone: "",
        code: "",
      },
      codeText: '获取验证码',
      codeDisStatus: false
    };
  },
  mounted() {
    console.log(this.$md5("222"));
  },
  methods: {
    blurCheck(from) {
      let formTextObj = {
        password: formCheck.checkPassword(this.formData[from]),
        newPhone: formCheck.checkPhone(this.formData[from]),
        code: formCheck.checkPhoneCode(this.formData[from]),
      };
      if (typeof formTextObj[from] === "string") {
        this.formDataError[from] = formTextObj[from];
        return false;
      }
      return true;
    },
    async getcode () {
        if (!this.blurCheck('password') || !this.blurCheck('newPhone')) {
            return;
        }
        // 接口校验当前密码是否错误 - 提示：您的当前密码错误，请重试  - 不发短信
        let data = {
            phone: this.formData.newPhone,
            password: this.$md5(this.formData.password)
        }
        let res = await this.$getAPI.registerCode(data)
        if (res.code === 1) {
            this.formDataError.password = '您的当前密码错误，请重试'
            return;
        }

        // 如果已有手机号
        if (res.code === 4) {
          this.$alert('此手机号已被绑定到其他账号，手机号仅可绑定一个账号', '请重新输入手机号', {
            confirmButtonText: '确定',
            callback: () => {}
          })
        }

        // 校验通过
        if (res.code === 2) {
          let s = 60
          let timer = setInterval(() => {
              if (s === 1) {
                  this.codeText = '获取验证码'
                  this.codeDisStatus = false
                  clearInterval(timer)
              } else {
                  this.codeText = `重新获取(${s}s)`
                  this.codeDisStatus = true
                  s--
              }
          },1000)
        }
    },
    async submit() {
      if (!this.blurCheck('password') || !this.blurCheck('newPhone') || !this.blurCheck('code')) {
          return;
      }
      let res = await this.$getAPI.check({
          phone: this.formData.newPhone,
          securityCode: this.formData.code,
          time: new Date().getTime()
      })
      if (res.code === 2) {
        this.formDataError.code = '您的验证码输入错误请重试'
        return;
      }
      console.log(res)
      if (res.code === 3) {
        this.formDataError.code = '您输入的验证码已失效，请重新获取'
        return;
      }

      if (res.code === 5) {
        this.formDataError.code = '请先获取验证码'
        return;
      }
      if (res.code === 6) {
        this.formDataError.newPhone = '获取验证码手机号非当前手机号，请修改'
        return;
      }

      if (res.code === 4) {
        this.$alert(res.msg, '提示',{
          confirmButtonText: '确定',
          callback: () => {}
        })
        return;
      }

      if (res.code === 1) {
        this.$alert('成功更换手机号，请重新登录', '提示',{
          confirmButtonText: '确定',
          callback: () => {
              this.$cookies.remove('TSESSIONID','/', process.env.VUE_APP_COOKIE_BASE);
              this.$cookies.remove('uscn','/', process.env.VUE_APP_COOKIE_BASE);
              window.location.replace(`${process.env.VUE_APP_LOGIN_URL}/?returnUrl=${encodeURIComponent(location.href)}`)
          }
        })
      }
    },
    close() {
      this.formData.phone = "";
      this.formData.password = "";
      this.formData.newPhone = "";
      this.formData.code = "";
      this.formDataError.phone = "";
      this.formData.password = "";
      this.formData.newPhone = "";
      this.formData.code = "";
      this.$emit("UpVisible", false);
    },
  },
};
</script>

<style lang="less" scoped>
.upPhone {
  position: absolute;
  left: 185px;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  h3 {
    // text-align: center;
    margin-top: 40px;
    margin-left: 80px;
  }
  ul {
    width: 360px;
    margin: 0 80px;
    margin-top: 40px;
    li {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }
  }
  &-do {
    width: 360px;
    margin-left: 80px;
    padding-left: 78px;
    margin-top: 40px;
  }
  &-label {
    width: 5em;
    text-align: right;
    line-height: 40px;
    margin-right: 8px;
  }
  &-item {
    display: flex;
    position: relative;
    /deep/.el-input--mini {
      width: 172px;
    }
    /deep/.el-input--mini .el-input__inner {
      width: 172px;
    }
    .getcode {
      margin-left: 5px;
      width: 100px;
      height: 28px;
      text-align: center;
    }
    i {
        font-style: normal;
        font-size: 10px;
        line-height: 10px;
        position: absolute;
        left: 0;
        bottom: -15px;
        color: #f00;
        white-space: nowrap;
    }
  }
}
</style>