<template>
  <div class="evaluate">
    <el-dialog :title="titleText" :visible.sync="flag" width="30%" :before-close="handleClose">
      <div class="evaluate-body">
        <div class="evaluate-body-item">
          <span>综合评价：</span>
          <el-rate v-model="index"></el-rate>
        </div>
        <div class="evaluate-body-item m-t-20">
          <span>评价内容：</span>
          <el-input type="textarea" v-model="words" placeholder="请输入评价内容"></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="reset">重 置</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Evaluate",
  props: ["dialogVisible",'bankName'],
  data() {
    return {
        index: 0,
      flag: false,
      words: ''
    };
  },
  computed: {
    titleText() {
        return `请您对“${this.bankName}”进行评价`
      }
    },
    watch: {
        dialogVisible(v) {
        this.flag = v
        this.words = ''
        }
    },
  methods: {
    reset() {
      this.index = 0
      this.words = ''
    },
    handleClose() {
      this.flag = false
      this.$emit('close')
    },
      close() {
        if (this.index > 0) {
          if (!this.words) {
            this.$message.error('请填写评价内容')
            return;
          }
          // 当评价数据大于0的时候才做记录调接口
          const index = this.index
          const words = this.words
          this.index = 0
          this.flag = false
          this.$emit('close', {index,words})
        } else {
          this.$message.error('请选择综合评价')
        }
    }
  }
};
</script>

<style lang="less" scoped>
.evaluate {
  &-body {
    // width: 210px;
    margin: 0 auto;
    &-item {
      display: flex;
      span {
        width: 6em;
      }
    }
  }
  /deep/ .el-rate__icon {
    font-size: 28px !important;
  }
  /deep/ .el-rate__text {
    font-size: 20px;
    // margin-left: 10px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
}
</style>