<template>
  <div class="evaluate">
    <el-dialog :title="titleText" :visible.sync="flag" width="30%" :before-close="handleClose">
      <div class="evaluate-body">
        <div class="evaluate-body-item">
          <span>综合评价：</span>
          <el-rate v-model="value" disabled text-color="#ff9900" show-text :texts="['超级差','差','一般','优秀','很优秀']">
          </el-rate>
        </div>
        <div class="evaluate-body-item m-t-20">
          <span>评价内容：</span>
          <p>{{words}}</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
export default {
  name: "Evaluate",
  props: ["dialogVisible", "bankName", "value", "words"],
  data() {
    return {
      flag: false,
    };
  },
  computed: {
    titleText() {
      return `对“${this.bankName}”进行的评价`;
    },
  },
  watch: {
    dialogVisible(v) {
      this.flag = v;
    },
  },
  methods: {
    handleClose() {
      this.flag = false;
      this.$emit("close", 0);
    },
    close() {
      this.flag = false;
      this.$emit("close");
    },
  },
};
</script>
  
  <style lang="less" scoped>
.evaluate {
  &-body {
    // width: 210px;
    margin: 0 auto;
    &-item {
      display: flex;
      span {
        width: 6em;
      }
    }
  }
  /deep/ .el-rate__icon {
    font-size: 28px !important;
  }
  /deep/ .el-rate__text {
    font-size: 14px;
    color: #999 !important;
      margin-left: 8px;
  }
  .m-t-20 {
    margin-top: 20px;
  }
}
</style>