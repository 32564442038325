<template>
  <div class="Mydialog" ref="bigbox">
    <el-dialog
      :close-on-click-modal="false"
      title="资料修改"
      :visible="flag"
       width="900px"
      :before-close="handleClose"
    >
    <div class="dialog-body">
      
      <div class="content">
        <div class="failed">
        <h3>审核失败文件:<span style="color: red;">(需要重新上传)</span></h3>
       <div  class="fail" v-for="item,index in failedlist" :key="index" >
            <div class="fname">
              <h4>{{ item.docName }}</h4>
            </div>
            <!-- 上传数据为空的时候 -->
            <div class="fkong" v-if="item.url.length==0" >
              <div class="konghetong" v-if="item.keyname=='constructionContract'">
                <label for="fileInput" class="custom-button">建筑合同上传</label>
                <input type="file" accept=".pdf" :ref="item.keyname+index" id="file-input" @change="upKonght(item,item.keyname+index)">
              </div>
              <div class="kongidcard" v-else-if="item.keyname=='legalPersonCardFront'">
                <div class="kongidcardone">
                  <label for="fileInput" class="custom-button">身份证正面上传</label>
                <input type="file" accept="image/*" :ref="item.keyname+index" id="file-input" @change="upKongidcard(item,item.keyname+index,1)">
                </div>
                <div class="kongidcardone">
                  <label for="fileInput" class="custom-button">身份证反面上传</label>
                <input type="file" accept="image/*" :ref="item.keyname+index" id="file-input" @change="upKongidcard(item,item.keyname+index,2)">
                </div>
              </div>
              <div class="kongidcard" v-else-if="item.keyname=='safetyProduction'">
                <div class="kongidcardone">
                  <label for="fileInput" class="custom-button">安全生产许可证第一面上传</label>
                <input type="file" accept="image/*" :ref="item.keyname+index" id="file-input" @change="upKongsafe(item,item.keyname+index,1)">
                </div>
                <div class="kongidcardone">
                  <label for="fileInput" class="custom-button">安全生产许可证第二面上传</label>
                <input type="file" accept="image/*" :ref="item.keyname+index" id="file-input" @change="upKongsafe(item,item.keyname+index,2)">
                </div>
              </div>
            </div>
            <!-- 上传是文件的时候 -->
            <div class="ffiles" ref="ffiles" v-else-if="item.docName=='公司最新章程'||item.docName=='建筑合同'">
              <div class="ffilesone" style="margin: 0;" v-for="(url,indexnum) in item.url" :key="indexnum">
                <a style="line-height: 28px;" :href="url">点击下载查看</a>
                <p>审核失败原因：<span style="color: red;">{{ item.checkNoReasons }}</span></p>
                <input type="file" :ref="item.keyname+indexnum" id="file-input"  @change="upFiles(item,item.keyname+indexnum,indexnum)" />
                <label for="fileInput" class="custom-button">重新上传</label>
              </div>
            </div>
            <div class="ffiles" ref="ffiles" v-else-if="item.docName=='近2年3大中标项目'">
              <div class="ffilesone" >
                <a style="line-height: 20px;" v-for="(url,indexnum) in item.url" :key="indexnum" :href="url">点击下载查看</a>
                <input type="file" ref="toThree" id="file-inputs" accept=".xls,.xlsx" @change="upFilescl()" multiple/>
                <label for="fileInput" class="custom-button">重新上传</label>
              </div>
              <p>审核失败原因：<span style="color: red;">{{ item.checkNoReasons }}</span></p>

            </div>
            <!-- 上传是图片的时候 -->
            <div class="fimg" ref="fimg" v-else>
              <div class="file-upload-wrapper" v-for="(url,indexnum) in item.url" :key="indexnum">
                <img @click="handlePictureCardPreview(url)" :src="url" alt="">
                <input type="file" :ref="item.keyname+indexnum" id="file-input"  @change="upFilesimg(item,item.keyname+indexnum,indexnum)" />
                <p>审核失败原因：<span style="color: red;">{{ item.checkNoReasons }}</span></p>
                <label for="fileInput" class="custom-button">重新上传</label>
              </div>
            </div>

       </div>
      </div>
      <div class="divider"></div> 
     <div class="success">
      <h3>上传成功文件:</h3>
      <p v-if="successlist.length==0">无内容</p>
      <div class="successone" v-for="item,index in successlist" :key="index" >
        <div class="successName">
          <h4>{{ item.docName }}</h4>
        </div>
        <div class="successCont" v-if="item.docName=='公司最新章程'||item.docName=='近2年3大中标项目'||item.docName=='建筑合同'">
          <a v-for="url,indexnum in item.url" :key="indexnum" :href="url">点击下载查看</a>
        </div>
        <div class="successContimg" v-else>
          <img v-for="url,indexnum in item.url" :key="indexnum" @click="handlePictureCardPreview(url)" :src="url" alt="">
        </div>
       </div>
     </div>
     <el-dialog :visible.sync="dialogVisibleimg" :modal="false">
      <img style="width: 100%;height: auto;" :src="dialogImageUrl" alt="">
    </el-dialog>

      </div>
    </div>
     
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog(1)">取 消</el-button>
        <el-button type="primary" @click="closeDialog(2)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["dialogVisible", "filesList"],
  data() {
    return {
      dialogImageUrl: "", //img放大
      dialogVisibleimg: false,
      disabled: false,
      subFiles: {
        processId: "",
        business: "",//这是营业执照修改测试数据
        licence: "",//这是开户许可证修改测试数据
        safetyProduction: "",//这是安全生产许可证修改测试数据
        safetyProductionSide: "",//这是安全生产许可证修改测试数据
        incorporation: "",//这是公司章程修改测试数据
        legalPersonCardFront: "",//这是法人身份证正面修改测试数据
        legalPersonCardSide: "",//这是法人身份证反面修改测试数据
        winBidProjects: ["", "", ""],
        toThreeFinancialSheets: "",//这是近两年每年前三期财务报表修改数据测试
        firstFinancialSheets: "",//这是近一期的财务报表修改测试数据
        bidWinningNotice: "",//这是中标通知书修改测试数据
        performanceGuarantee: "",//这是履约保函凭证修改测试数据
        constructionContract: "",//这是建筑合同修改测试数据
      },
      list: [], //所有数据
      flag: false, //dialog开关
      winBidProjectsArrs: [], //近2年3大中标项目
      idcardArr: [], //身份证正反
      safeTyProeuctions: [], //安全生产许可证
      successlist:[],//上传成功列表
      failedlist:[],//上传失败列表
      files:[],
      uploadval:{},
    };
  },
  watch: {
    dialogVisible(val) { //监听弹窗开关状态
      this.$nextTick(()=>{
        this.setlimit()
      })
      this.flag = val;
      this.list = this.filesList;
      this.classifyWay()
      this.setuploadval()
      this.uploadval.processId=this.list.processId;
    },
  },
  destroyed(){
      this.list=[]
      this.successlist=[]
      this.failedlist=[]
      this.winBidProjectsArrs=[]
      this.uploadval={}
      this.safeTyProeuctions=[]
      this.flag=false
  },
  methods: {
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url;
      this.dialogVisibleimg = true;
    },
    handleDownload(file) {
      window.open(file.docUrl)
    },

    //弹框关闭方法
    handleClose() {
      this.$emit("close");
      this.list=[]
      this.successlist=[]
      this.failedlist=[]
      this.winBidProjectsArrs=[]
      this.uploadval={}
      this.safeTyProeuctions=[]
      this.idcardArr=[]
      
    },
    closeDialog(val) {
      this.$emit("close");
      if(val==2){
        let data=(this.uploadval);
        console.log(data,'----------');
        this.$getAPI.saveShenheUrl(data).then((res)=>{
          console.log(res,'save');
          this.$emit("getOrderList")
        })
      }
      this.list=[]
      this.successlist=[]
      this.failedlist=[]
      this.winBidProjectsArrs=[]
      this.idcardArr=[]
      this.safeTyProeuctions=[]
      this.uploadval={}
    },
    //获取上传数据
    setuploadval(){
      this.failedlist.forEach((item)=>{
        if(item.docName=='近2年3大中标项目'){
          this.uploadval.winBidProjects=item.url
        }else if(item.docName=='身份证'){
          this.uploadval.legalPersonCardFront=item.url[0]
          this.uploadval.legalPersonCardSide=item.url[1]
        }else if(item.docName=='安全生产许可证'){
          this.uploadval.safetyProduction=item.url[0]
          this.uploadval.safetyProductionSide=item.url[1]
        }else{
          let key=item.keyname
          this.uploadval[key]=item.url[0]
        }
      })
    },
    //list分类
    classifyWay(){
        for (const key in this.list) {
          if(key=='safetyProduction'){
            this.list[key].forEach((item)=>{
              this.safeTyProeuctions.push(item.docUrl)
            })
          }
          if(key=='legalPersonCardFront'){
            this.list[key].forEach((item)=>{
              this.idcardArr.push(item.docUrl)
            })
          }
          console.log(this.idcardArr,this.safeTyProeuctions,'asdfghjkl');
          
          if (key!=='processId') {
            if(this.list[key].length==0){
             let obj={docName:'',url:[],checkNoReasons:'',keyname:''}
                  if(key=='bidWinningNotice'){
                  obj.docName='中标通知书';
                  obj.keyname='bidWinningNotice'
                  } else if(key=='businessLicense'){
                    obj.docName='营业执照';
                  obj.keyname='businessLicense'
                  }else if (key=='licence') {
                    obj.docName='开户许可证';
                    obj.keyname='licence'
                  }else if (key=='constructionContract') {
                    obj.docName='建筑合同';
                    obj.keyname='constructionContract'
                  }else if (key=='firstFinancialSheets') {
                    obj.docName='近一期财务报表';
                    obj.keyname='firstFinancialSheets'
                  }else if (key=='incorporation') {
                    obj.docName='公司最新章程';
                    obj.keyname='incorporation'
                  }else if (key=='performanceGuarantee') {
                    obj.docName='履约保函凭证';
                    obj.keyname='performanceGuarantee'
                  }else if (key=='toThreeFinancialSheets') {
                    obj.docName='近两年财务报表';
                    obj.keyname='toThreeFinancialSheets'
                  }else if (key=='legalPersonCardFront') {
                    obj.docName='身份证'
                    obj.keyname='legalPersonCardFront'
                  }else if (key=='safetyProduction') {
                    obj.docName='安全生产许可证'
                    obj.keyname='safetyProduction'
                  }else if (key=='winBidProjects') {
                    obj.docName='近2年3大中标项目'
                    obj.keyname='winBidProjects'
                  }
              this.failedlist.push(obj)
            }else{
              if( this.list[key].length>0&&this.list[key][0].checkStatus==2){
                let obj={docName:this.list[key][0].docName,url:[]}
                for (let i = 0; i < this.list[key].length; i++) {
                  obj.url.push(this.list[key][i].docUrl)
                }
                this.successlist.push(obj)
              }else if(this.list[key].length>0&&this.list[key][0].checkStatus==3){
                let obj={docName:this.list[key][0].docName,url:[],checkNoReasons:this.list[key][0].checkNoReasons,keyname:key}
              for (let i = 0; i < this.list[key].length; i++) {
                obj.url.push(this.list[key][i].docUrl)
              }
                this.failedlist.push(obj)
              }
            }
          }
        }
        console.log(this.successlist,'上传成功列表');
        console.log(this.failedlist,'上传失败列表');
    },
    upFilesimg(val, index,num) {//上传是图片的时候
      let inp = this.$refs[index];
      let file = inp[0].files[0];
      console.log(val, 'item');
      console.log(file, 'file');
      console.log(num, 'num');
          // 处理上传文件的逻辑
          for (const key in this.list) {
            if (key !== 'processId' && val.keyname === key) {
              if (key !== 'legalPersonCardFront' && key !== 'safetyProduction') {
                const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res) => {
                  let url = res.url;
                  this.failedlist.forEach((item)=>{
                    if(item.docName==val.docName){
                      item.url[0]=url;
                      this.$forceUpdate()
                    }
                  })
                  this.uploadval[key] = url;
                  console.log(url, '返回的url');
                  console.log(this.uploadval, 'this.uploadval');
                }).catch(error => {
                  console.error('上传失败:', error);
                });
              }else if(key == 'safetyProduction'){
                const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res) => {
                  let url = res.url;
                    this.safeTyProeuctions[num]=url
                  this.uploadval.safetyProduction = this.safeTyProeuctions[0];
                  this.uploadval.safetyProductionSide = this.safeTyProeuctions[1];
                  this.failedlist.forEach((item)=>{
                    if(item.docName=='安全生产许可证'){
                      item.url[0]=this.safeTyProeuctions[0];
                      item.url[1]=this.safeTyProeuctions[1];
                      this.$forceUpdate()
                    }
                  })
                  console.log(url, '返回的url');
                }).catch(error => {
                  console.error('上传失败:', error);
                });
              }else if(key == 'legalPersonCardFront'){
                
                const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res) => {
                  let url = res.url;
                  this.idcardArr[num]=url
                  this.uploadval.legalPersonCardFront = this.idcardArr[0];
                  this.uploadval.legalPersonCardSide = this.idcardArr[1];
                  this.failedlist.forEach((item)=>{
                    if(item.docName=='身份证'){
                      item.url[0]=this.idcardArr[0];
                      item.url[1]=this.idcardArr[1];
                      this.$forceUpdate()
                    }
                  })
                  
                  console.log(url, '返回的url');
                }).catch(error => {
                  console.error('上传失败:', error);
                });
              }
            }
          }  
    },
    upFiles(val,index,num){//获取文件url
      let inp=this.$refs[index] 
      let file=inp[0].files[0]
      console.log(file,'file');    
      console.log(num,'num');    
     for (const key in this.list) {
        if(key!=='processId'&&val.keyname==key&&val.keyname!=='近2年3大中标项目'){
          console.log(key,'key+++++');
           
          const formData = new FormData();
             formData.append('file', file);
             this.$getAPI.getShenheUrl(formData).then((res)=>{
               let url=res.url
                   this.failedlist.forEach((item)=>{
                    if(item.docName==val.docName){
                      item.url[0]=url
                      this.uploadval[key]=url
                      this.$forceUpdate()
                    }
                   })
               console.log(url,'返回的url');
          })
        }
     }
    },
    upKonght(val,index){//建筑合同为空的时候
      let inp=this.$refs[index] 
      console.log(inp,'inp');   
      let file=inp[0].files[0]
      console.log(val,'valllll');
      
      console.log(file,'file');    
      for (const key in this.list) {
          if(key!=='processId'&&val.keyname==key){
              const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res)=>{
                let url=res.url
                
                this.uploadval[key] = url;
                console.log(this.uploadval,'*******');
                
          })
          }
      }
    },
    upKongsafe(val,index,num){//安全生产许可证为空的时候
      let inp=this.$refs[index] 
      console.log(inp,'inp');   
      let file=inp[0].files[0]
      console.log(file,'file');    
      for (const key in this.list) {
          if(key!=='processId'&&val.keyname==key){
              const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res)=>{
                let url=res.url
                console.log(url,'返回的url');
                
                if(num==1){
                  this.uploadval.safetyProduction=url
                }else if(num==2){ 
                  this.uploadval.safetyProductionSide=url
                }
          })
          }
      }
    },
    upKongidcard(val,index,num){//身份证为空的时候
      let inp=this.$refs[index] 
      console.log(inp,'inp');   
      let file=inp[0].files[0]
      console.log(file,'file');    
      for (const key in this.list) {
          if(key!=='processId'&&val.keyname==key){
              const formData = new FormData();
                formData.append('file', file);
                this.$getAPI.getShenheUrl(formData).then((res)=>{
                let url=res.url
                if(num==1){
                  this.uploadval.legalPersonCardFront=url
                }else{
                  this.uploadval.legalPersonCardSide=url
                }
                console.log(url,'返回的url');
          })
          console.log(key,'kkkk');
          
          }
      }
    },
    setlimit(){//限制input上传文件类型
      let inpfile= document.querySelectorAll('.ffiles input')
          inpfile.forEach((item)=>{
          item.setAttribute('accept','.xls,.xlsx,application/pdf')
        })
      let inpimg= document.querySelectorAll('.fimg input')
          inpimg.forEach((item)=>{
          item.setAttribute('accept','image/jpeg, image/png, image/jpg')
        })
    },
    upFilescl(){//近两年3大目标上传修改
            this.winBidProjectsArrs=[]
            let files=this.$refs.toThree[0].files
            console.log(files,'files');
            let result = {}; // 创建一个新对象来存储结果  
            Object.keys(files).forEach(key => {  
                if ('name' in files[key]) {  
                    result[key] = files[key];  
                }  
            }); 
            for (const key in result) {
              const formData = new FormData();
              formData.append('file', result[key]);
              this.$getAPI.getShenheUrl(formData).then((res)=>{
                let url=res.url
                this.winBidProjectsArrs.push(url)
              })
            }
           this.failedlist.forEach((item)=>{
              if(item.docName=='近2年3大中标项目'){
                item.url=this.winBidProjectsArrs
                console.log(item,'item');
                
              }
           })
            this.uploadval.winBidProjects=this.winBidProjectsArrs
            this.$forceUpdate()
    }
  },
 
};
</script>
<style lang="less" scoped>
// .el-dialog__body{
//   padding: 20px 20px!important;
// }
.Mydialog h4{
  width: 800px;
}
.Mydialog h5{
  width: 200px;
}
.content{
  width: 800px;
  margin-top: 0;
}
.successone{
width: 800px;
// height: 200px;
margin: 10px 0;
display: flex;
}
.successName{
  width: 200px;
}
.successContimg{
  width: 600px;
  height: 200px;
}
.successContimg img{
  width: 200px;
  height: 180px;
  margin-right: 20px;
}

.successCont{
display: flex;
flex-direction: column;
}
.successCont a{
  display: block;
  margin: 5px 0;
}
.dialog-body{
  max-height: 400px; /* 根据需要设置合适的高度 */
  overflow-y: scroll; /* 始终显示滚动条 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 和 Edge */
}
.el-dialog__body::-webkit-scrollbar {
  width: 0px; /* Chrome, Safari 和 Opera */
  background: transparent; /* Optional: 使背景透明 */
}
.failedone{
  width: 800px;
  height: 250px;
  margin: 10px 0;
  display: flex;
}
  /* 隐藏文件选择框 */
  // #file-input {
  //     // opacity: 0;
  //     border: 0;
  //   }
  .file-upload-wrapper {
            position: relative;
            display: inline-block;
        }
        .file-upload-wrapper input[type="file"] {
            position: absolute;
            left: 0;
            bottom: 3px;
            width: 72px;
            height: 28px;
            opacity: 0; /* 隐藏原始的文件输入元素 */
            cursor: pointer;
        }
        .konghetong{
          position: relative;
        }
        .konghetong input[type="file"] {
            position: absolute;
            left: 0;
            top: 0px;
            width: 105px;
            height: 28px;
            opacity: 0; /* 隐藏原始的文件输入元素 */
            cursor: pointer;
        }
        .kongidcardone{
          position: relative;
        }
        .kongidcardone input[type="file"] {
            position: absolute;
            left: 0;
            top: 0px;
            width: 105px;
            height: 28px;
            opacity: 0; /* 隐藏原始的文件输入元素 */
            cursor: pointer;
        }
        .custom-button {
            display: inline-block;
            padding: 0px 4px;
            background-color:#B10B18 ;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
    .rebtn{
      width: 60px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color:#B10B18 ;
      color: white;
      border: 0;
    }
    .file-upload-wrapper{
      min-width: 200px;
      height: 250px;
    }
    .fail{
      display: flex;
      margin-bottom: 15px;
    }
    .fail .fname{
      width: 200px;
      height: 50px;
    }
    .fimg img{
      width: 200px;
      height: 180px;
    }
    .ffilesone{
      width: 600px;
      display: flex;
      justify-content: space-between;
      margin: 5px;
      position: relative;
    }
    .ffilesone input{
      position: absolute;
      right: -180px;
      top: 0px;
      opacity: 0; /* 隐藏原始的文件输入元素 */
      cursor: pointer;
    }
    .fimg .file-upload-wrapper{
      margin-right:20px;
      min-width: 200px;
    }

// .ffilestoThree{
//   margin: 5px 0;
// }
    /deep/ .el-dialog__body {  
      padding-top: 10px; /* 修改为你想要的 padding 值 */  
    } 
    .divider {  
      height: 2px; /* 分割线的高度 */  
      // background-color:#000; /* 分割线的颜色 */  
      margin: 20px 20px; /* 分割线上下的外边距 */  
      width: 100%; /* 分割线的宽度 */  
      background: none; /* 确保背景透明 */  
      border: none; /* 移除其他边框 */  
      border-top: 1px dashed #000; /* 顶部边框为虚线 */ 
    } 
</style>
