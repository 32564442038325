<template>
  <div class="invoice">
    <Menu></Menu>
    <div class="flex-1 content bor bgfff">
      <div class="public-title flex flex-pack-justify">
        <strong class="p_r">保函校验</strong>
      </div>
      <div class="myOrder-search paging">
        <div class="myOrder-search-item">
          <label>保单编号</label>
          <el-input class="searchId" v-model="orderCode" placeholder="" size="medium"></el-input>
        </div>
        <div class="myOrder-search-btn">
          <el-button type="primary" @click="searchGetOrderList">去校验</el-button>
          <el-button id="reset" @click="clear">重置</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import Menu from "../components/Menu.vue";
export default {
  name: "checkInsure",
  components: {
    Menu,
  },
  data() {
    return {
      orderCode: "",
    };
  },
  methods: {
    searchGetOrderList() {
      if (this.orderCode === "") {
        this.$message.error("请输入保单编号");
        return;
      }
      this.checkOrder();
    },
    async checkOrder() {
      const r = await this.$getAPI.checkOrder({ POLICYNO: this.orderCode });
      const res = r.data
      if (res.code === 200) {
        if (res.result.resultCode === 1) {
          if (res.result.resultMsg === 'error') {
            this.$message.error("对应保单不存在，请检查后重新校验");
          }else if (res.result.resultData && res.result.resultData.list) {
            if (res.result.resultData.list.ORG_LETTER_CHECK_PATH) {
              window.open(res.result.resultData.list.ORG_LETTER_CHECK_PATH)
            } 
          }
        }
      }
    },
    clear() {
      this.orderCode = "";
    },
  },
};
</script>
  
  <style lang="less" scoped>
.invoice {
  margin-left: 185px;
  .paging {
    margin: 40px 20px;
  }
  .fapiao {
    td:nth-child(2),
    td:nth-child(3) {
      text-align: left;
      padding-left: 2em;
      line-height: 28px;
    }
  }
  .row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    span {
      white-space: nowrap;
    }
    p {
      margin: 0;
    }
  }
  .btns {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .el-table__header {
    border-top: 2px#E60012 solid;
  }
}
.myOrder {
  &-search {
    // margin-top: 20px;
    display: flex;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
      margin-left: 20px;
      border-radius: 8px;
      height: 32px;
      label {
        background-color: #ba1216;
        color: #fff;
        line-height: 32px;
        padding: 0 10px;
        border-radius: 8px 0 0 8px;
      }
      /deep/ .el-input__inner {
        height: 32px;
        line-height: 32px;
        border: 1px solid #959595;
        border-left: 0;
        overflow: hidden;
        border-radius: 0 8px 8px 0;
      }
      /deep/ .searchId {
        width: 260px;
      }
    }
    &-btn {
      display: inline-block;
      margin-left: 20px;
      #search {
        background-image: url(../assets/images/search.png);
        background-repeat: no-repeat;
        background-position: 7px 7px;
        padding-left: 36px;
        height: 40px;
        margin: 20px 0;
      }
      #reset {
        width: 80px;
        border: 1px#BA1216 solid;
        color: #ba1216;
        background-color: #fff;
        border-radius: 4px;
        margin-left: 20px;
      }
    }
  }
}
</style>
  <style lang="less">
.myOrder-search {
  .el-select .el-input.is-focus .el-input__inner {
    border: 0;
  }
  .el-input__inner {
    border: 0;
  }
  .el-input--small .el-input__inner {
    height: 32px;
    padding-right: 0;
  }
  .el-input__suffix-inner {
    position: relative;
    right: 20px;
  }
}
.pagebtn {
  border: 1px solid #dcdcdc;
  box-shadow: 0px 2px 4px 0px rgb(0 117 230 0.77);
  border-radius: 4px;
  background: #fff;
  margin-left: 10px;
}
// .el-table--scrollable-y .el-table__body-wrapper {
//   &::-webkit-scrollbar {
//     width:1px;
//     height:16px;
//     background-color:#d6d6d6;
//   }

//   &::-webkit-scrollbar-thumb
//   {
//     border-radius:10px;
//     background-color:#333;
//   }
// }
</style>