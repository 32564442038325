<template>
  <div class="menu bgfff">
    <div class="sidebar">
      <h4 @click="getUrl('/')" :class="{active: path === '/'}">全部功能</h4>
      <div v-for="(v,k) in routerData" :key="k">

        <a v-if="v.show" href="javascript:;" @click="getUrl(v.routerKey,path === v.routerKey)" class="di_bl" :class="{'on': path === v.routerKey}">{{v.routerName}} <el-badge v-if="v.dot" :value="v.dot" class="item"></el-badge></a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menus",
  props: ['notRead'],
  data() {
    return {
      routerData: [
        {
          routerKey: "/collection",
          routerName: "我的收藏",
          show: true,
          dot: false,
        },
        // {routerKey: '/history', routerName: '历史浏览'},
        {
          routerKey: "/myorder",
          routerName: "我的订单",
          show: true,
          dot: false,
        },
        {
          routerKey: "/invoice",
          routerName: "我的发票",
          show: true,
          dot: false,
        },
        {
          routerKey: "/myMessage",
          routerName: "我的信息",
          show: true,
          dot: false,
        },
        {
          routerKey: "/enterMsg",
          routerName: "认证信息",
          show: true,
          dot: false,
        },
        {
          routerKey: "/switchId",
          routerName: "切换账号",
          show: true,
          dot: false,
        },
        {
          routerKey: "/upPassword",
          routerName: "修改密码",
          show: true,
          dot: false,
        },
        { routerKey: "/message", routerName: "消息通知", show: true, dot: 0 },
        {
          routerKey: "/checkInsure",
          routerName: "保函校验",
          show: true,
          dot: false,
        },
        // {routerKey: '/address', routerName: '我的收货地址'},
      ],
      path: "/",
    };
  },
  watch: {
    notRead(n, o) {
      if (n !== o) {
        const index = this.routerData.findIndex(v => v.routerName === "消息通知")
        this.routerData[index].dot = n
      }
    }
  },
  mounted() {
    this.path = this.$route.path;
    this.upPhone();
    this.getNotReadNumber();
  },
  methods: {
    async getNotReadNumber() {
      const res = await this.$getAPI.getNoitce();
      const index =  this.routerData.findIndex(v => v.routerName === "消息通知")
      this.routerData[index].dot = res.data.result.resultData.total;
    },
    async upPhone() {
      const res = await this.$getAPI.judgeUser();
      if (res.code !== 1) {
        const k = this.routerData.findIndex((v) => v.routerName === "修改密码");
        this.routerData[k].show = true;
      }
    },
    getUrl(url, flag) {
      if (flag) return;
      this.path = url;
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 165px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-top: 2px#E61F26 solid;
  z-index: 1;
  h4 {
    cursor: pointer;
  }
  h4.active {
    color: #e51920;
  }
  a:hover {
    font-size: 16px;
    color: #e51920;
  }
}
</style>